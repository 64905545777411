<template>
  <div class="boxes cb-view">
    <DownloadFlyout />

    <div class="boxes__header">
      <h1>Kundenübersicht</h1>
    </div>
    <!-- BOXESFILTER START-->
    <div class="boxes__filter-wrapper">
      <Filter @filter="onFilter"></Filter>

      <BulkChange
        :patients="getSelectedPatients()"
        :total-patients-count="totalPatientsCount"
        @change="loadPatients"
      ></BulkChange>
    </div>
    <!-- BOXESFILTER END-->
    <div class="boxesTable" :class="{ '--visible': !isLoading }">
      <table>
        <!-- Table Header row -->
        <tr
          class="tableHeaderWrapper"
          v-show="!isLoading && totalPatientsCount"
        >
          <!-- Checkbox -->
          <th class="--checkbox">
            <BaseCheckbox
              label=""
              v-model="allPatientsSelected"
              :disabled="isCheckboxDisabled()"
            >
            </BaseCheckbox>
          </th>
          <!-- Name, patientID, nextDelivery -->
          <!-- Sort Direction IF active -->
          <th
            v-for="(header, index) in tableHeaders"
            :key="index"
            :class="[
              `--${header.column}`,
              {
                '--active': store.boxesTable.sortColumn === header.column,
              },
            ]"
            @click="sort(header.column)"
          >
            <div class="flex-row">
              <span class="bold">{{ header.label }}</span>
              <span
                v-if="store.boxesTable.sortDirection === 'desc'"
                class="icon icon-arrow-down-20px"
              ></span>
              <span v-else class="icon icon-arrow-up-20px"></span>
            </div>
          </th>
          <!-- Product icons -->
          <th
            class="--image"
            v-for="product in store.products"
            :key="product.id"
          >
            <ProductImage :product="product" :small="true" />
          </th>
          <th></th>
        </tr>
        <!-- Table Row Patient - Loop -->
        <tr
          class="patient"
          :class="patient.isCancelled ? 'is-cancelled' : ''"
          v-for="patient in patients"
          v-show="!isLoading"
          :key="patient.id"
          @click="
            !patient.isCancelled ? router.push(`box/${patient.id}`) : null
          "
          @mouseleave="openMenuId = null"
        >
          <td @click.stop>
            <BaseCheckbox
              label=""
              v-model="patient.selected"
              v-if="!hasSubmittedCaseOrIsCancelled(patient)"
            ></BaseCheckbox>
          </td>
          <td class="bold">
            {{ `${patient.lastname}, ${patient.firstname}` }}
          </td>
          <td>{{ patient.customer_number }}</td>
          <td>
            {{ formatDate(patient.next_delivery_date) }}
          </td>
          <td
            class="__product bold"
            v-for="product in store.products"
            :key="product.id"
          >
            {{
              product.isPG51
                ? getPG51ProductAmount(patient)
                : getProductAmount(product, patient) || '-'
            }}
          </td>
          <td :class="['patient__case-status', patientHasStatusLabel(patient)]">
            <div class="patient__case-status__content">
              {{ getCaseStatus(patient) }}
            </div>
            <BoxesPatientsMenu
              v-if="!hasSubmittedCaseOrIsCancelled(patient)"
              :patient-id="patient.id"
              :is-open="openMenuId === patient.id"
              @open="openMenuId = patient.id"
              @close="openMenuId = null"
            />
          </td>
        </tr>
      </table>

      <div id="noData" v-if="!isLoading && !totalPatientsCount">
        Für den Filter/die Suche konnten wir keine Ergebnisse anzeigen. Passen
        Sie bitte Ihren Filter / Ihre Suche an.
      </div>
    </div>
    <div id="pagination" v-if="totalPatientsCount">
      <span>
        {{
          `${
            (store.boxesTable.currentPage - 1) * PATIENTS_NUMBER_PER_PAGE + 1
          } - ${Math.min(
            store.boxesTable.currentPage * PATIENTS_NUMBER_PER_PAGE,
            totalPatientsCount,
          )} von ${totalPatientsCount} curaboxen`
        }}
      </span>
      <cbPagination
        :current-page="store.boxesTable.currentPage"
        :page-count="pageCount"
        @goToPage="goToPage"
      />
    </div>
  </div>
</template>

<script setup>
import { watch, ref, inject } from 'vue';
import { useStore } from '@/store/index';
import { useRouter } from 'vue-router';
import { formatDate, getCaseStatus, getNextRegularBoxes } from '@/mixins/mixin';
import Filter from '@/components/dashboard/filter/Filter.vue';
import BulkChange from '@/components/dashboard/bulk-change/BulkChange.vue';
import ProductImage from '@/components/ProductImage.vue';

const router = useRouter();
const store = useStore();
const apiService = inject('apiService');

const tableHeaders = ref([
  {
    column: 'lastname',
    label: 'Nachname, Vorname',
  },
  {
    column: 'customer_number',
    label: 'Kundennr.',
  },
  {
    column: 'next_delivery_date',
    label: 'nächste Lieferung',
  },
]);
const patients = ref([]);
const allPatientsSelected = ref(false);
const totalPatientsCount = ref(0);
const pageCount = ref(0);
const isLoading = ref(true);
const openMenuId = ref(null);

const PATIENTS_NUMBER_PER_PAGE = 10;
setPageCount();
loadPatients();

async function loadPatients() {
  const params = {
    page: store.boxesTable.currentPage,
    sortColumn: store.boxesTable.sortColumn,
    sortDirection: store.boxesTable.sortDirection,
  };

  isLoading.value = true;
  const response = await apiService.get(
    '/patients',
    getParamsWithFilters(params),
  );

  if (response.patients) {
    response.patients.forEach((patient) => {
      patient.selected = false;
      patient.isCancelled = patient.status === 'cancelled';
    });

    patients.value = response.patients;
    isLoading.value = false;
  }
  allPatientsSelected.value = false;
}

async function setPageCount() {
  const params = {};
  const countResponse = await apiService.get(
    '/patients/count',
    getParamsWithFilters(params),
  );
  totalPatientsCount.value = countResponse.count;
  pageCount.value = Math.ceil(
    totalPatientsCount.value / PATIENTS_NUMBER_PER_PAGE,
  );
}

function getParamsWithFilters(params) {
  if (
    store.boxesTable.simpleFilterActive &&
    store.boxesTable.simpleFilterValue
  ) {
    params.simpleFilter = store.boxesTable.simpleFilterValue;
  }

  if (store.boxesTable.extendedFilterActive) {
    params.extendedFilter = {};
    Object.keys(store.boxesTable.extendedFilterValues).forEach((key) => {
      const value = store.boxesTable.extendedFilterValues[key];
      if (value !== null && (!Array.isArray(value) || value.length)) {
        params.extendedFilter[key] = value;
      }
    });
  }

  return params;
}

function toggleAll() {
  patients.value
    .filter((patient) => !hasSubmittedCaseOrIsCancelled(patient))
    .forEach((patient) => {
      patient.selected = allPatientsSelected.value;
    });
}

function goToPage(page) {
  store.boxesTable.currentPage = page;
  loadPatients();
}

function sort(_sortColumn) {
  if (store.boxesTable.sortColumn === _sortColumn) {
    store.boxesTable.sortDirection =
      store.boxesTable.sortDirection === 'asc' ? 'desc' : 'asc';
  }
  store.boxesTable.sortColumn = _sortColumn;
  store.boxesTable.currentPage = 1;
  loadPatients();
}

function onFilter() {
  store.boxesTable.currentPage = 1;
  setPageCount();
  loadPatients();
}

function getProductAmount(product, patient) {
  let ret = 0;
  if (!patient.orders.length || patient.isCancelled) {
    return ret;
  }
  const nextRegularBoxes = getNextRegularBoxes(patient);
  nextRegularBoxes[0]?.products.forEach((p) => {
    if (p.salesforceId === product.salesforceId) {
      ret += p.amount;
    }
  });
  return ret;
}

function getPG51ProductAmount(patient) {
  return patient.pg51_order && !patient.isCancelled
    ? patient.pg51_order.pg51_product_amount
    : '-';
}

function getSelectedPatients() {
  return patients.value.filter((p) => p.selected);
}

function hasSubmittedCaseOrIsCancelled(patient) {
  return patient.case?.status === 'submitted' || patient.isCancelled;
}

function isCheckboxDisabled() {
  return patients.value.every((patient) =>
    hasSubmittedCaseOrIsCancelled(patient),
  );
}

function patientHasStatusLabel(patient) {
  let ret = '';

  if (patient.case) {
    ret = `patient__case-status--${patient.case.status}`;
  }
  if (patient.isCancelled) {
    ret = 'patient__case-status--cancelled';
  }

  return ret;
}

watch(allPatientsSelected, () => toggleAll());

defineExpose({ loadPatients });
</script>

<style lang="scss" scoped>
#curabox-professional .boxes {
  :deep(.base-checkbox) {
    margin: 8px 1rem 1rem;

    & input {
      left: -100vw;
      opacity: 0;
      position: absolute;
      cursor: pointer;
    }

    & .success-icon {
      position: absolute;
      z-index: 4;
      top: -13px;
      left: -4px;
      cursor: pointer;
      width: 26px;
    }

    & label {
      position: absolute;
      display: inline-block;
      max-width: calc(100% - 60px);
      font-size: 14px;
      line-height: 1.5;
      color: var(--font-color-primary);
      left: 0;
      z-index: 2;
      padding-top: 0;
      cursor: pointer;

      &:before {
        content: '';
        width: 32px;
        height: 32px;
        display: block;
        box-sizing: border-box;
        border-radius: 0;
        border: 1px solid var(--outline);
        z-index: 0;
        position: relative;
        left: -8px;
        top: -16px;
        background-color: var(--background-inputs);
        cursor: pointer;
      }
    }
  }

  background-color: var(--secondary-mint-lighter);

  &__header {
    position: relative;
    margin: auto;
    width: fit-content;
  }

  &__filter-wrapper {
    margin: 22px 0 10px;
  }
}

.boxesTable {
  // Enable to properly debug positioning problems
  // * {
  //   border: 1px solid pink;
  // }
  -webkit-transform: translateZ(0) scale(1, 1);
  min-height: 890px;
  opacity: 0;
  transition: opacity 0.3s linear;

  &.--visible {
    opacity: 1;
  }

  table {
    border-collapse: separate;
    border-spacing: 0 10px;
    width: 100%;
    max-width: 100%;
    table-layout: auto;
  }

  tr {
    height: 70px;

    th {
      &:not(.--checkbox) + th:not(.--image) {
        padding-left: 2rem;
      }

      &.--lastname,
      &.--customer_number,
      &.--next_delivery_date {
        position: relative;
        cursor: pointer;
        white-space: nowrap;

        .icon {
          display: none;
          width: 0;
        }
      }

      &.--lastname {
        padding-left: 2rem;
      }

      .--next_delivery_date {
        padding-right: 40px;
      }

      &.--active {
        color: var(--primary-blue-dark);

        .icon {
          display: block;
        }
      }
      .product-image {
        padding: 0 5px;
      }
    }
  }

  .patient {
    cursor: pointer;
    border-radius: 4px;

    &.is-cancelled {
      cursor: default;
    }

    td {
      white-space: nowrap;
      height: inherit;
      background-color: var(--white);

      &:not(:first-child) {
        padding-left: 2rem;
      }

      &:first-child {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
      }

      &:last-child {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
      }
    }

    &:hover td {
      background-color: var(--primary-blue-lightest);
    }

    &__case-status {
      display: flex;
      align-items: center;

      &__content {
        font-size: 14px;
        padding: 5px 15px;
        border-radius: 50px;
        width: 190px;
        min-width: 190px;
        margin: auto;
      }

      &--reserved {
        border-right: 5px solid var(--support-warning);

        .patient__case-status__content {
          background-color: var(--support-warning-light);
        }
      }

      &--submitted {
        border-right: 5px solid var(--support-success);

        .patient__case-status__content {
          background-color: var(--support-success-light);
        }
      }

      &--cancelled {
        border-right: 5px solid var(--lighter-grey);

        .patient__case-status__content {
          background-color: var(--lighter-grey);
          text-align: center;
        }
      }
    }
  }
}

div.boxes-patients-menu {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  padding-right: 15px;
}

#no-data {
  text-align: center;
  position: relative;
  top: 110px;
}

#pagination {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 36px;
}
</style>
