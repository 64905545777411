<template>
  <div
    class="box-edit box-details cb-view"
    v-if="patient && store.products.length"
  >
    <cbBreadcrumb :routeNames="['Boxes', 'BoxDetails', 'BoxEdit']" />
    <BoxHeader :patient="patient" />
    <BoxProgressbar
      :patient="patient"
      :total-price="totalPrice"
      :class="[{ 'progressbar--sticky': isSticky }]"
    />

    <div class="box-details__wrapper flex-row">
      <IconLink icon-name="reset" label="Box leeren" @click="resetBox()" />
      <div class="column">
        <div class="column__header bolder">Beliebteste Zusammenstellungen</div>
        <BoxSelection
          v-for="box in store.boxes"
          :key="box.id"
          :box="box"
          :is-selected="selectedBoxId === box.id"
          @select="selectBox(box)"
        />
      </div>
      <div class="column">
        <div class="column__header bolder">Produkte</div>
        <ProductRow
          v-for="product in store.products"
          :key="product.id"
          :patient="patient"
          :product="product"
          :box-product="getBoxProduct(product)"
          :approved="isProductApproved(product)"
          :show-flex-amount="true"
          :show-selected-amount="false"
          :edit-glove-options="true"
          :total-price="totalPrice"
          @add="addProduct(product)"
          @remove="removeProduct(product)"
          @validGloveSelection="validGloveOptions = $event"
          @gloveSelection="updateGloveSelection"
        />
        <BaseButton
          label="Änderungen übernehmen"
          @click="reserveCase()"
          :outline="true"
          :disabled="!isValid()"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted, watch, inject } from 'vue';
import { useStore } from '@/store/index';
import { useRouter, useRoute } from 'vue-router';
import { getNextRegularBoxes, getObjectCopy } from '@/mixins/mixin';

const store = useStore();
const route = useRoute();
const router = useRouter();

const apiService = inject('apiService');
const showHint = inject('showHint');

const emit = defineEmits(['cases-change']);

const patient = ref(null);
const boxProducts = ref([]);
const oldProducts = ref([]);
const totalPrice = ref(0);
const selectedBoxId = ref(null);
const validGloveOptions = ref(true);
const isSticky = ref(false);

onMounted(async () => {
  window.addEventListener('scroll', onScroll);
  const response = await apiService.get(`/patients/${route.params.id}`);
  patient.value = response.data;
  setBoxProducts();
});

onUnmounted(() => {
  window.removeEventListener('scroll', onScroll);
});

function onScroll() {
  const progressbar = document.querySelector('.box-progressbar');
  isSticky.value = progressbar?.getBoundingClientRect().top <= 0;
}

function isProductApproved(_product) {
  return patient.value.approvedProducts.pg54.includes(_product.salesforceId);
}

// Selecting a box, setting amount for products on right side
function selectBox(box) {
  if (box.id === selectedBoxId.value) {
    resetBox();
  } else {
    selectedBoxId.value = box.id;

    /* eslint-disable no-param-reassign */
    box.products.forEach((product) => {
      const boxProduct = boxProducts.value.find(
        (p) => p.salesforceId === product.salesforceId,
      );
      if (boxProduct) {
        boxProduct.amount = isProductApproved(product) ? product.amount : 0;
      }
    });
  }
}

function resetBox() {
  selectedBoxId.value = null;
  boxProducts.value.forEach((p) => {
    p.amount = 0;
  });
}

function setBoxProducts() {
  if (patient.value && store.products.length) {
    if (patient.value.case?.products?.length > 1) {
      boxProducts.value = patient.value.case.products;
    } else {
      const nextRegularBoxes = getNextRegularBoxes(patient.value);
      boxProducts.value = nextRegularBoxes[0]?.products ?? [];
    }

    store.products.forEach((product) => {
      if (
        !boxProducts.value.find((p) => p.salesforceId === product.salesforceId)
      ) {
        boxProducts.value.push({
          salesforceId: product.salesforceId,
          amount: 0,
        });
      }
    });

    setBoxGloveOptions();
    oldProducts.value = getObjectCopy(boxProducts.value);
  }
}

function setBoxGloveOptions(updateSelection = false, selectedOption) {
  const gloves = boxProducts.value.find(
    (p) => p.salesforceId === store.glovesSalesforceId,
  );

  if (updateSelection) {
    // gloves.X needed to make sure checkDifference() is still working
    gloves.gloveSize = selectedOption.gloveSize;
    gloves.gloveIntolerances = [];

    selectedOption.gloveIntolerances.forEach((intolerance) => {
      const option = store.gloveOptions.materials.find(
        (material) => material.value === intolerance,
      );
      gloves.gloveIntolerances.push(option);
    });

    boxProducts.value.gloveSize = selectedOption.gloveSize;
    boxProducts.value.gloveIntolerances = selectedOption.gloveIntolerances;
    boxProducts.value.gloveIntolerancesOptionsOpen =
      selectedOption.gloveIntolerancesOptionsOpen;
  } else {
    gloves.gloveSize =
      patient.value.case?.glove_size ?? patient.value?.glove_size;
    gloves.gloveIntolerances =
      patient.value.case?.glove_intolerances ??
      patient.value?.glove_intolerances;
  }
}

function setTotalPrice() {
  /* eslint-disable no-nested-ternary */
  totalPrice.value = boxProducts.value
    .filter((p) => !store.product(p.salesforceId).isPG51)
    .map((p) => store.product(p.salesforceId).price * p.amount)
    .reduce((a, b) => a + b);
}

function getBoxProduct(product) {
  return boxProducts.value.find((p) => p.salesforceId === product.salesforceId);
}

// add Count
function addProduct(product) {
  getBoxProduct(product).amount += 1;
}

// reduce Count
function removeProduct(product) {
  getBoxProduct(product).amount -= 1;
}

// save changes made for submission, WONT SUBMIT THEM YET
async function reserveCase() {
  const response = await apiService.post('/cases/products', {
    patientId: route.params.id,
    products: boxProducts.value,
    glove_intolerances: boxProducts.value.gloveIntolerances,
    glove_size: boxProducts.value.gloveSize,
  });

  if (response.message === 'success') {
    await store.fetchReservedCasesCount();
    await router.push(`/box/${route.params.id}`);
    emit('cases-change');
    showHint({ id: 'reserveCase', data: null });
  } else {
    showHint({ id: 'reserveCaseError', data: null });
  }
}

// checks if changes have been made and are valid
function checkDifference() {
  let status = false;
  oldProducts.value.forEach((_oldProduct) => {
    const newProduct = boxProducts.value.find(
      (p) => p.salesforceId === _oldProduct.salesforceId,
    );

    // specifically check if selected glove intolerance changed
    if (_oldProduct.salesforceId === store.glovesSalesforceId) {
      newProduct.gloveIntolerances.forEach((newValue) => {
        const isIntoleranceDifference = _oldProduct.gloveIntolerances.find(
          (p) => p.name === newValue.name,
        );
        if (isIntoleranceDifference === undefined) {
          status = true;
        }
      });
    }

    if (
      _oldProduct.amount !== newProduct.amount ||
      (_oldProduct.salesforceId === store.glovesSalesforceId &&
        _oldProduct.gloveSize.id !== newProduct.gloveSize)
    ) {
      status = true;
    }
  });
  return status;
}

function isValid() {
  return (
    totalPrice.value >= store.minPrice &&
    validGloveOptions.value &&
    checkDifference()
  );
}

function updateGloveSelection(selectedOption) {
  setBoxGloveOptions(true, selectedOption.value);
}

watch(store.products, () => {
  setBoxProducts();
});

watch(
  boxProducts,
  () => {
    setTotalPrice();
  },
  { deep: true },
);
</script>
